<script>
import { toRefs, ref } from 'vue';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat, split } from 'apollo-link';

import Loader from './components/Loader.vue';
import Button from './components/Button.vue';
import { appConfig } from './config';
import heroIcon from './assets/images/hero-icon.png';
import logo from './assets/icons/logo.svg';
import empToken from './assets/icons/emp-token.svg';
import { useFetch } from './composables/useFetch';
import { useState } from './composables/useState';
import { useTier } from './composables/useTier';

export default {
  components: {
    Loader,
    Button,
  },
  setup() {
    const { fetchData, checkToken } = useFetch();
    const { findTier } = useTier();
    const { state, setState } = useState();
    const options = { ...appConfig };

    setState({
      data: null,
      address: null,
      error: null,
      loading: null,
    });

    const GET_DELEGATORS = gql`
      query rewards($stakeKey: String) {
        delegator(query: { stake_key: $stakeKey }) {
          total_emp
          tier_bonus_emp
        }
        rewards(query: { _parent_id: { stake_key: $stakeKey } }) {
          _id
          epoch_emp
          epoch
          delegated_ada
          fcnft_level
          _parent_def
          _parent_field
          _created
          _version
        }
      }
    `;
    const httpLink = new HttpLink({
      uri: `https://${options.api.mongodb.domain}/api/client/v2.0/app/${options.api.mongodb.appId}/graphql`,
      credentials: 'include',
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      const token = localStorage.getItem('token');
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return forward(operation);
    });

    // Graphql Config
    const apolloClient = new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache(),
    });

    const transformRewardData = data => {
      const rewards = data.data.rewards ? data.data.rewards : [];
      const delegator = data.data.delegator ? data.data.delegator : {};
      const { total_emp: total, tier_bonus_emp: subtotal } = delegator;
      const items = rewards.map(item => {
        return {
          ...item,
          epoch: item.epoch - 1,
        };
      });

      const rewardsCount = items.length;
      const { tier } = findTier(rewardsCount);

      return {
        tier,
        items,
        total,
        subtotal,
      };
    };

    // ##############
    // Deprecated
    // ##############
    // const transformData = data => {
    //   const { total_emp: total, tier_bonus_emp: subtotal, rewards } = data;

    //   const items = rewards.map(item => {
    //     return {
    //       ...item,
    //       epoch: item.epoch - 1,
    //     };
    //   });

    //   const rewardsCount = items.length;
    //   const { tier } = findTier(rewardsCount);

    //   return {
    //     tier,
    //     items,
    //     total,
    //     subtotal,
    //   };
    // };

    const getStakeKey = async address => {
      const { loading, error, result } = fetchData({
        url: `${options.api.blockfrost.endpoint}/addresses/${address}`,
        headers: {
          method: 'GET',
          project_id: options.api.blockfrost.key,
        },
      });

      setState({
        loading,
        error,
      });

      const response = await result.value;

      if (!response.error) {
        return response.stake_address;
      }

      setState({
        error: response.error.message || error.value,
      });

      return null;
    };

    // const getData = async address => {
    //   const { loading, error, result } = fetchData({
    //     url: `${options.api.restdb.endpoint}?q={"stake_key": "${address}"}&fetchchildren=true`,
    //     headers: {
    //       apikey: options.api.restdb.key,
    //     },
    //   });

    //   setState({
    //     loading,
    //     error,
    //   });

    //   const response = await result.value;

    //   if (response) {
    //     return transformData(response[0]);
    //   }

    //   return null;
    // };

    const getGraphqlData = async address => {
      const tokenAsync = await checkToken();
      if (tokenAsync.error) {
        return null;
      }

      const response = await apolloClient.query({
        query: GET_DELEGATORS,
        variables: { stakeKey: address },
      });

      if (response && response.data.delegator) {
        return transformRewardData(response);
      }
      setState({ data: null, error: 'Sorry we cannot find any records for this address' });

      return null;
    };

    const handleSubmit = async event => {
      event.preventDefault();
      const loading = ref(false);
      let address = event.target.elements.address.value;

      if (state.address === address) {
        return;
      }

      setState({ address, error: null, loading });

      // Validate field
      if (!address.startsWith('addr') && !address.startsWith('stake')) {
        setState({ data: null, error: 'Please enter an address that begins with "addr" or "stake"' });
        return;
      }

      if (address.startsWith('addr')) {
        address = await getStakeKey(address);
      }
      loading.value = true;
      const data = await getGraphqlData(address);
      loading.value = false;

      setState({ address, data, loading });
    };

    return {
      ...toRefs(state),

      // handlers
      handleSubmit,

      // assets
      heroIcon,
      logo,
      empToken,
    };
  },
};
</script>

<template>
  <section class="app">
    <header class="app__header header">
      <div class="header__container">
        <a class="header__logo" href="#">
          <img :src="logo" alt="Empowa logo" />
        </a>
        <nav class="nav">
          <ul class="nav__list">
            <li class="nav__item"><a class="nav__link" href="https://empowa.io">Home</a></li>
            <li class="nav__item"><a class="nav__link" href="https://empowa.io/ispo/">ISPO</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <main class="app__main">
      <section class="section section__hero hero">
        <div class="hero__content">
          <img :src="heroIcon" alt="ISPO Calculator icon" class="hero__icon" />
          <h1>ISPO - EMP</h1>
          <h2 style="position: relative; display: inline-block">Rewards Calculator</h2>
        </div>
      </section>
      <section class="section section__ispo ispo-calculator">
        <div class="section__container">
          <form class="ispo-calculator__form" @submit="handleSubmit">
            <h2>Please enter your wallet address</h2>
            <input
              name="address"
              id="address"
              class="ispo-calculator__input"
              type="text"
              placeholder='Starting with "addr..." or "stake..."'
            />
            <p v-if="loading">
              <Loader class="ispo-calculator__loader">Calculating and getting results... </Loader>
            </p>
            <p v-if="error">
              {{ error }}
            </p>
            <Button class="ispo-calculator__button">Calculate rewards</Button>
          </form>
          <div v-if="data" class="ispo-calculator__result">
            <article class="ispo-calculator__highlight">
              <img class="ispo-calculator__token" :src="empToken" alt="EMP token" />
              <div>
                <h2 class="ispo-calculator__heading">Total EMP Rewards</h2>
                <p class="ispo-calculator__amount">{{ data.total.toLocaleString('us-Us') }}</p>
              </div>
            </article>
            <article class="ispo-calculator__history">
              <header class="ispo-calculator__header">
                <h2 class="ispo-calculator__heading">Delegation History</h2>
                <p v-if="data?.tier">Loyalty tier: {{ data.tier }}</p>
              </header>
              <div class="ispo-calculator__table">
                <table class="ispo-calculator__table table">
                  <thead class="table__header">
                    <tr>
                      <td class="table__column">Active Epoch</td>
                      <td class="table__column table__number">ADA Delegated</td>
                      <td class="table__column">Founding Community NFT Level</td>
                      <td class="table__column table__number">EMP Earned</td>
                    </tr>
                  </thead>
                  <tbody class="table__body">
                    <tr class="table__row" v-for="item in data.items" :key="item.epoch">
                      <td class="table__column">{{ item.epoch || '-' }}</td>
                      <td class="table__column table__number">
                        {{ item.delegated_ada ? item.delegated_ada.toLocaleString('us-Us') : '-' }}
                      </td>
                      <td class="table__column">{{ item.fcnft_level || '-' }}</td>
                      <td class="table__column table__number">
                        {{ item.epoch_emp ? item.epoch_emp.toLocaleString('us-Us') : '-' }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="table__footer">
                    <tr class="table__row" v-if="data.subtotal">
                      <td class="table__column">Loyalty Bonus</td>
                      <td class="table__column"></td>
                      <td class="table__column"></td>
                      <td class="table__column table__number">{{ data.subtotal.toLocaleString('us-Us') }}</td>
                    </tr>
                    <tr class="table__row">
                      <td class="table__column">Total</td>
                      <td class="table__column"></td>
                      <td class="table__column"></td>
                      <td class="table__column table__number">{{ data.total.toLocaleString('us-Us') }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </article>
          </div>
          <div v-else-if="data?.items === []">No results to show...</div>
          <div class="ispo-calculator__disclaimer">
            <hr />
            <p style="line-height: 1.3; margin-bottom: 1rem">
              <strong> Our ISPO ended at the completion of epoch 335. Thank you to everyone who participated. </strong>
            </p>
            <p>
              This calculator now reports the total number of EMP tokens earned by wallets that delegated during the
              ISPO. Tokens can be claimed via <a href="https://dripdropz.io" target="_blank">DripDropz</a>, according to
              the 6 month vesting period.
            </p>
            <p>
              If you have any questions regarding your calculated EMP rewards or how to claim, please contact us at
              <a href="mailto:support@empowa.io">support@empowa.io</a>, on
              <a href="https://t.me/emp_tokensalesupport" target="_blank">Telegram</a> or via
              <a href="https://discord.gg/53hZwSTPMq" target="_blank">Discord</a>.
            </p>
          </div>
        </div>
      </section>
    </main>
    <footer class="app__footer"></footer>
  </section>
</template>

<style lang="scss">
@import './App.scss';
</style>
