import { ref } from 'vue';
import VueJwtDecode from 'vue-jwt-decode';
import { appConfig } from '../config';

export const useFetch = () => {
  const appConfigs = { ...appConfig };
  const fetchAccessToken = () => {
    const result = ref(null);
    const loading = ref(false);
    const error = ref(null);

    result.value = fetch(
      `https://${appConfigs.api.mongodb.domain}/api/client/v2.0/app/${appConfigs.api.mongodb.appId}/auth/providers/api-key/login`,
      {
        method: 'POST',
        body: JSON.stringify({ key: appConfigs.api.mongodb.key }),
        headers: {
          'content-type': 'application/json',
        },
      },
    )
      .then(async response => {
        const dataJson = await response.json();
        // console.log('Token Response:  ', dataJson);
        return dataJson.access_token;
      })
      .catch(e => {
        error.value = e;
        return e;
      })
      .finally(() => {
        loading.value = false;
      });

    return {
      result,
      loading,
      error,
    };
  };

  const fetchData = ({ url, method, body = null, options = null, headers = null }) => {
    const result = ref(null);
    const loading = ref(false);
    const error = ref(null);

    loading.value = true;

    result.value = fetch(url, {
      method,
      body,
      options: {
        ...options,
      },
      headers: {
        ...headers,
      },
    })
      .then(async res => {
        const contentType = res.headers.get('content-type');
        let resData;

        if (contentType && contentType.includes('application/json')) {
          resData = await res.json();
        } else {
          resData = await res.text();
        }

        if (res.status >= 400 && res.status < 600) {
          if (resData) {
            const message = resData?.message || resData?.error?.message || resData;
            throw new Error(message);
          }

          throw new Error('Network error: could not fetch request...');
        }

        return resData;
      })
      .catch(err => {
        console.error(err.message);
        error.value = err.message;
      })
      .finally(() => {
        loading.value = false;
      });

    return {
      loading,
      error,
      result,
    };
  };

  const checkToken = async () => {
    const token = localStorage.getItem('token');
    let isValid = false;
    if (token) {
      const decoded = VueJwtDecode.decode(token);
      console.log('Check', decoded.exp * 1000, Date.now());
      isValid = decoded.exp * 1000 > Date.now();
    }
    if (!isValid) {
      const { result: accessToken } = fetchAccessToken();
      const newToken = await accessToken.value;
      localStorage.setItem('token', newToken);
    }
    return isValid;
  };

  return {
    fetchData,
    checkToken,
  };
};
