<script>
export default {
  name: 'Button',
  props: {
    disabled: Boolean,
    href: String,
    target: String,
    variant: String,
    color: String,
  },
  computed: {
    className() {
      const { variant } = this;

      if (variant) {
        return `button--${variant}`;
      }

      return false;
    },
  },
};
</script>

<template>
  <a v-if="href" class="button" :class="className" :href="href" :target="target">
    <span class="button__text"><slot /></span>
  </a>
  <button v-else class="button" :class="className" :disabled="disabled">
    <span class="button__text"><slot /></span>
  </button>
</template>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

.button {
  align-items: center;
  background-color: $color-primary;
  border-radius: 4px;
  border: solid 2px $color-primary;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  height: 42px;
  line-height: 42px;
  padding: 0 1.5rem;
  text-decoration: none;
  transition: background-color 0.25s, border 0.25s, color 0.25s;

  &:hover {
    background-color: darken(#f9792f, 10%);
  }

  &--primary {
    background: $color-primary;
    border: solid 2px $color-primary;

    &:hover {
      background-color: darken(#f9792f, 10%);
    }
  }

  &--secondary {
    background-color: #410000;
    border-color: #410000;

    &:hover {
      background-color: darken(#410000, 10%);
    }
  }

  &--ghost,
  &--ghost-primary {
    background-color: transparent;
    border-color: $color-primary;
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &--ghost-secondary {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;

    &:hover {
      background-color: $color-secondary;
      color: #fff;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #bbb;
    border-color: #bbb;
    color: #fff;

    &:hover {
      background-color: #bbb;
      border-color: #bbb;
      color: #fff;
    }
  }
}
</style>
