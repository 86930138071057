import { ref } from 'vue';

export const useTier = () => {
  const tier = ref(null);

  const findTier = rewardsCount => {
    if (rewardsCount < 10) {
      tier.value = 'Tier 1';
    }

    if (rewardsCount >= 10 && rewardsCount < 15) {
      tier.value = 'Tier 2';
    }

    if (rewardsCount >= 15) {
      tier.value = 'Tier 3';
    }

    return {
      tier: tier.value,
    };
  };

  return {
    findTier,
  };
};
