const {
  // Deprecated
  // VUE_APP_RESTDB_API_ENDPOINT,
  // VUE_APP_RESTDB_API_KEY,
  VUE_APP_BLOCKFROST_API_ENDPOINT,
  VUE_APP_BLOCKFROST_API_KEY,
  VUE_APP_MONGODB_APP_ID,
  VUE_APP_MONGODB_DOMAIN,
  VUE_APP_MONGODB_API_KEY,
} = process.env;

export const appConfig = {
  api: {
    // Deprecated
    // restdb: {
    //   endpoint: VUE_APP_RESTDB_API_ENDPOINT,
    //   key: VUE_APP_RESTDB_API_KEY,
    // },
    blockfrost: {
      endpoint: VUE_APP_BLOCKFROST_API_ENDPOINT,
      key: VUE_APP_BLOCKFROST_API_KEY,
    },
    mongodb: {
      domain: VUE_APP_MONGODB_DOMAIN,
      appId: VUE_APP_MONGODB_APP_ID,
      key: VUE_APP_MONGODB_API_KEY,
    },
  },
};
